import React, { useEffect, useState } from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  StatusBar,
  SafeAreaView,
  ScrollView,
  FlatList,
  Dimensions,
  ActivityIndicator,
  Modal,
  Platform,
  Image,
  Linking,
} from 'react-native';
import { StackNavigationProp } from '@react-navigation/stack';
import { RouteProp, useFocusEffect } from '@react-navigation/native';
import { Ionicons } from '@expo/vector-icons';
import { firestore, auth } from '../../firebaseConfig';
import { getDoc, collection, getDocs, updateDoc, doc, query, where } from 'firebase/firestore';
import VendorCard from './VendorCard';
import VendorProfileScreen from './VendorProfileScreen';

const ITEM_WIDTH = Platform.OS === 'web' ? '24%' : 140;

type HomePageProps = {
  navigation: StackNavigationProp<any>;
  route: RouteProp<{ Home: { location: string; categories: string[] } }, 'Home'>;
};

type Vendor = {
  vendorName: string;
  location: string;
  category: string;
  userId: string;
  promoted?: boolean;
  promotionExpiration?: number | null;
  averageRating?: number;
};

type Ad = {
  id: string;
  imageUrl: string;
  vendorId?: string;
  websiteUrl?: string;
  expired?: string;
  screenLink?: "Recommendations";
};

export default function HomePage({ navigation }: HomePageProps) {
  const [loading, setLoading] = useState(true);
  const [location, setLocation] = useState<string | null>(null);
  const [categories, setCategories] = useState<string[]>([]);
  const [ads, setAds] = useState<Ad[]>([]);
  const [vendorData, setVendorData] = useState<{ [key: string]: Vendor[] }>({});
  const [selectedVendor, setSelectedVendor] = useState<Vendor | null>(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const fetchUserData = async () => {
    const user = auth.currentUser;
    if (user) {
      try {
        const userRef = doc(firestore, 'users', user.uid);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists()) {
          const userData = userSnap.data();
          setLocation(userData.location);
          setCategories(userData.categories);
        } else {
          setErrorMessage('User data not found');
        }
      } catch (error) {
        setErrorMessage('Error fetching user data');
      }
    }
  };

  const fetchAds = async () => {
    try {
      const adsCollection = collection(firestore, 'ads');
      const adsSnapshot = await getDocs(adsCollection);
      const allAds = adsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })) as Ad[];

      const activeAds = allAds.filter(ad => !ad.expired);
      const shuffledAds = activeAds.sort(() => Math.random() - 0.5);

      setAds(shuffledAds);
    } catch (error) {
      setErrorMessage('Error fetching ads');
    }
  };

  const fetchVendors = async () => {
    if (!location || categories.length === 0) {
      setLoading(false);
      return;
    }

    try {
      const vendorCollection = collection(firestore, 'vendors');
      const vendorQuery = query(vendorCollection, where('location', '==', location));
      const vendorQuerySnapshot = await getDocs(vendorQuery);

      const vendorsByCategory: { [key: string]: Vendor[] } = {};
      vendorQuerySnapshot.forEach((doc) => {
        const vendor = doc.data() as Vendor;
        
        if (categories.includes(vendor.category)) {
          if (!vendorsByCategory[vendor.category]) {
            vendorsByCategory[vendor.category] = [];
          }
          // Mark valid, non-expired promotions
          vendor.promoted = vendor.promoted && vendor.promotionExpiration && vendor.promotionExpiration > Date.now();
          vendorsByCategory[vendor.category].push(vendor);
        }
      });

      // Sort vendors by promotion and average rating
      for (const category in vendorsByCategory) {
        vendorsByCategory[category].sort((a, b) => {
          if (a.promoted !== b.promoted) return b.promoted ? 1 : -1;
          return (b.averageRating || 0) - (a.averageRating || 0);
        });
      }

      setVendorData(vendorsByCategory);
      setErrorMessage(null);
      setLoading(false);
    } catch (error) {
      setErrorMessage('Error fetching vendor data');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  useEffect(() => {
    if (location && categories.length > 0) {
      fetchVendors();
      fetchAds();
    }
  }, [location, categories, navigation]);

  const handleAdPress = async (ad: Ad) => {
    if (ad.id) {
      const adRef = doc(firestore, 'ads', ad.id);
      try {
        await updateDoc(adRef, {
          clickCount: (ad.clickCount || 0) + 1,
        });
      } catch (error) {
        console.error("Error updating click count:", error);
      }
    }

    if (ad.vendorId) {
      const vendor = Object.values(vendorData).flat().find((v) => v.userId === ad.vendorId);
      if (vendor) {
        setSelectedVendor(vendor);
        setModalVisible(true);
      } else {
        console.log('Vendor not found');
      }
    } else if (ad.websiteUrl) {
      let url = ad.websiteUrl;
      if (!url.startsWith('http://') && !url.startsWith('https://')) {
        url = `https://${url}`;
      }
      Linking.openURL(url).catch((err) => console.error('Failed to open URL', err));
    } else if (ad.screenLink?.toLowerCase() === "recommendations") {
      navigation.navigate("Recommendations");
      return;
    } else {
      console.log("No valid action for this ad:", ad);
    }
  };

  const renderAdCarousel = () => (
    <View style={styles.adCarouselContainer}>
      <FlatList
        data={ads}
        keyExtractor={(_, index) => index.toString()}
        horizontal
        showsHorizontalScrollIndicator={false}
        renderItem={({ item }) => (
          <TouchableOpacity
            style={styles.adTouchableCard}
            onPress={() => handleAdPress(item)}
          >
            <View style={styles.adImageContainer}>
              <Image source={{ uri: item.imageUrl }} style={styles.adImage} resizeMode="cover" />
            </View>
          </TouchableOpacity>
        )}
      />
    </View>
  );

  const renderVendorCarousel = (category: string) => {
    const categoryVendors = vendorData[category] || [];

    if (categoryVendors.length === 0) {
      return (
        <View key={category} style={styles.noVendorsContainer}>
          <Text style={styles.noVendorsText}>No vendors available in {category} right now. If you have some places in mind, we would love to hear your recommendations.</Text>
        </View>
      );
    }

    return (
      <View key={category} style={styles.vendorSection}>
        <View style={styles.vendorHeader}>
          <Text style={styles.vendorTitle}>Most Popular {category} in {location}</Text>
          <TouchableOpacity
            onPress={() => navigation.navigate('AllVendorsScreen', { category, location })}
          >
            <Text style={styles.seeAllText}>See All</Text>
          </TouchableOpacity>
        </View>

        <FlatList
          data={categoryVendors}
          keyExtractor={(item) => item.userId}
          numColumns={Platform.OS === 'web' ? 4 : 1}
          columnWrapperStyle={Platform.OS === 'web' && styles.columnWrapper}
          horizontal={Platform.OS !== 'web'}
          showsHorizontalScrollIndicator={Platform.OS !== 'web'}
          ItemSeparatorComponent={() => <View style={{ width: 10 }} />}
          renderItem={({ item }) => (
            <TouchableOpacity
              style={styles.touchableCard}
              onPress={() => {
                setSelectedVendor(item);
                setModalVisible(true);
              }}
            >
              <VendorCard vendor={item} isPromoted={item.promoted} />
              {item.promoted && <Text style={styles.adLabel}>Ad</Text>}
            </TouchableOpacity>
          )}
        />
      </View>
    );
  };

  return (
    <View style={styles.container}>
      <ScrollView contentContainerStyle={styles.scrollContainer}>
        <StatusBar barStyle="dark-content" backgroundColor="transparent" translucent={true} />

        <SafeAreaView>
          <View style={styles.selectorRow}>
            <TouchableOpacity onPress={() => navigation.navigate('LocationSelector')} style={[styles.selectionBox, styles.selectorItem]}>
              <Ionicons name="location-outline" size={20} color="#333" />
              <Text style={styles.selectionText}>{location || 'Select Location'}</Text>
            </TouchableOpacity>

            <TouchableOpacity onPress={() => navigation.navigate('CategorySelector', { location })} style={[styles.selectionBox, styles.selectorItem]}>
              <Ionicons name="list-outline" size={20} color="#333" />
              <Text style={styles.selectionText}>{categories.length ? categories.join(', ') : 'Select Categories'}</Text>
            </TouchableOpacity>
          </View>

          {renderAdCarousel()}

          {errorMessage ? (
            <Text style={styles.errorText}>{errorMessage}</Text>
          ) : null}

          {loading ? (
            <ActivityIndicator size='large' color="#FFB6C1" />
          ) : (
            categories.map((category) => renderVendorCarousel(category))
          )}
        </SafeAreaView>
      </ScrollView>

      {selectedVendor && (
        <Modal visible={modalVisible} animationType="slide" onRequestClose={() => setModalVisible(false)}>
          <VendorProfileScreen vendor={selectedVendor} isExplorer={true} onClose={() => setModalVisible(false)} />
        </Modal>
      )}

      {/* Footer for Web */}
      {Platform.OS === 'web' && (
        <View style={styles.footer}>
          <View style={styles.footerContent}>
            <Text style={styles.footerText}>© 2024 awayy app. All rights reserved.</Text>
            <View style={styles.footerLinks}>
              <TouchableOpacity onPress={() => navigation.navigate('PrivacyPolicy')}>
                <Text style={styles.linkText}>Privacy Policy</Text>
              </TouchableOpacity>
              <TouchableOpacity onPress={() => navigation.navigate('TermsofService')}>
                <Text style={styles.linkText}>Terms of Service</Text>
              </TouchableOpacity>
              <TouchableOpacity onPress={() => navigation.navigate('Contact')}>
                <Text style={styles.linkText}>Contact Us</Text>
              </TouchableOpacity>
            </View>
            <View style={styles.socialMediaIcons}>
              <Ionicons name="logo-tiktok" size={24} color="#93C572" />
              <TouchableOpacity onPress={() => Linking.openURL('https://www.instagram.com/aweyytoday/')}>
                <Ionicons name="logo-instagram" size={24} color="#93C572" style={styles.iconSpacing} />
              </TouchableOpacity>
            </View>
          </View>
        </View>
      )}
    </View>    
  );
}

const styles = StyleSheet.create({
  adLabel: {
    position: 'absolute',
    top: 5,
    left: 5,
    backgroundColor: '#fff',
    color: '#a4a4a4',
    paddingHorizontal: 5,
    paddingVertical: 2,
    borderRadius: 5,
    fontSize: 10,
    fontWeight: 'bold',
  },
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
  scrollContainer: {
    paddingBottom: 80,
    paddingHorizontal: 15,
  },
  selectorRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 15,
  },
  selectionBox: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#fff',
    paddingVertical: 10,
    paddingHorizontal: 15,
    borderRadius: 15,
    borderWidth: 2,
    borderColor: '#FFB6C1',
    alignSelf: 'flex-start',
  },
  selectorItem: {
    flex: 1,
    marginRight: 10,
  },
  selectionText: {
    fontSize: 16,
    fontWeight: '600',
    color: '#333',
    marginLeft: 5,
    flex: 1,
    flexWrap: 'wrap',
    maxWidth: '100%',
  },
  vendorSection: {
    marginBottom: 30,
    marginTop: 0,
  },
  vendorHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  vendorTitle: {
    fontSize: 20,
    fontWeight: '700',
    color: '#4A4A4A',
    marginBottom: 15,
  },
  seeAllText: {
    fontSize: 16,
    color: '#93C572',
    fontWeight: '600',
  },
  touchableCard: {
    width: ITEM_WIDTH,
    marginBottom: 15,
  },
  columnWrapper: {
    justifyContent: 'flex-start',
    gap: 10,
  },
  noVendorsContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
  },
  noVendorsText: {
    fontSize: 16,
    fontWeight: '600',
    color: '#333',
    textAlign: 'center',
  },
  errorText: {
    color: 'red',
    textAlign: 'center',
    marginBottom: 10,
  },
  adCarouselContainer: {
    height: 250,
    marginBottom: 15,
  },
  adTouchableCard: {
    width: Platform.OS === 'web' ? Dimensions.get('window').width * 0.30 : Dimensions.get('window').width * 0.8,
    marginRight: 15,
  },
  adImageContainer: {
    width: '100%',
    height: Platform.OS === 'web' ? 250 : 200,
    borderRadius: 15,
    overflow: 'hidden',
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
  adImage: {
    width: '100%',
    height: '100%',
    resizeMode: 'cover',
  },
  footer: {
    backgroundColor: '#fff',
    padding: 20,
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 100,
  },
  footerContent: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 20,
  },
  footerText: {
    color: '#93C572',
    fontSize: 14,
    fontWeight: 'bold',
  },
  footerLinks: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '30%',
  },
  linkText: {
    color: '#93C572',
    fontSize: 14,
    fontWeight: 'bold',
  },
  socialMediaIcons: {
    flexDirection: 'row',
  },
  iconSpacing: {
    marginHorizontal: 10,
  },
});
