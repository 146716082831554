import React from 'react';
import {
  View,
  Text,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  SafeAreaView,
} from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import { Ionicons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';

export default function TermsofServiceScreen() {
  const navigation = useNavigation();

  return (
    <LinearGradient colors={['#FFDEE9', '#ffffff']} style={styles.background}>
      <SafeAreaView style={styles.safeArea}>
        {/* Header */}
        <View style={styles.header}>
          <TouchableOpacity onPress={() => navigation.goBack()} style={styles.backButton}>
            <Ionicons name="arrow-back" size={22} color="#333" />
          </TouchableOpacity>
          <Text style={styles.headerTitle}>Terms of Service</Text>
        </View>

        {/* Content */}
        <ScrollView contentContainerStyle={styles.contentContainer}>
          <Text style={styles.sectionTitle}>Effective Date: 1 January 2025</Text>

          <Text style={styles.content}>
            Welcome to AWEYY! By using our app, you agree to comply with these Terms of Service ("Terms"). Please read them carefully. If you do not agree, do not use our services.
          </Text>

          <Text style={styles.subTitle}>1. Use of the App</Text>
          <Text style={styles.content}>
            1.1 Purpose: AWEYY is designed to help users discover and connect with places, save favorites, and share experiences.{'\n\n'}
            1.2 Eligibility: To use the app, you must be at least 13 years old. Users under 18 must have parental consent.{'\n\n'}
            1.3 Account Responsibility: You are responsible for maintaining the security of your account. Activities under your account are your responsibility.{'\n\n'}
            1.4 Restrictions: You may not use the app for illegal purposes, post harmful content, or attempt unauthorized data access.
          </Text>

          <Text style={styles.subTitle}>2. Content</Text>
          <Text style={styles.content}>
            2.1 User-Generated Content: Users can upload reviews, ratings, and posts. By submitting content, you grant AWEYY a non-exclusive, worldwide license to use, display, and distribute your content within the app.{'\n\n'}
            2.2 Prohibited Content: Users may not post inappropriate, offensive, or infringing material.
          </Text>

          <Text style={styles.subTitle}>3. Favorites and Sharing</Text>
          <Text style={styles.content}>
            3.1 Favorites: You can add vendors to your favorites for easy access. Favorites are private to your account.{'\n\n'}
            3.2 Sharing Vendors: You may share vendor profiles with friends.
          </Text>

          <Text style={styles.subTitle}>4. Payments and Promotions</Text>
          <Text style={styles.content}>
            4.1 Paid Services: If AWEYY introduces paid features (e.g., vendor promotions), users will be informed of fees and conditions before purchase.{'\n\n'}
            4.2 Refunds: Refunds for paid features, if applicable, are subject to our Refund Policy.
          </Text>

          <Text style={styles.subTitle}>5. Reviews and Ratings</Text>
          <Text style={styles.content}>
            5.1 Honest Reviews: Users must provide truthful and unbiased reviews. False reviews may result in account suspension.{'\n\n'}
            5.2 Review Visibility: Reviews and ratings are public and viewable by all app users.
          </Text>

          <Text style={styles.subTitle}>6. Privacy</Text>
          <Text style={styles.content}>
            Your use of the app is subject to our Privacy Policy. We prioritize the security of your data but cannot guarantee complete safety from breaches.
          </Text>

          <Text style={styles.subTitle}>7. Vendor Interactions</Text>
          <Text style={styles.content}>
            AWEYY facilitates connections between users and vendors but does not oversee transactions, products, or services. Issues with vendors should be resolved directly with them.
          </Text>

          <Text style={styles.subTitle}>8. Termination</Text>
          <Text style={styles.content}>
            We reserve the right to suspend or terminate accounts for violations of these Terms.
          </Text>

          <Text style={styles.subTitle}>9. Liability</Text>
          <Text style={styles.content}>
            AWEYY is not liable for loss or damage from using the app, issues with vendors or other users, or unauthorized access to your account.
          </Text>

          <Text style={styles.subTitle}>10. Changes to the Terms</Text>
          <Text style={styles.content}>
            We may update these Terms from time to time. Users will be notified of significant changes. Continued use of the app constitutes acceptance of the updated Terms.
          </Text>

          <Text style={styles.subTitle}>11. Contact Us</Text>
          <Text style={styles.content}>
            If you have questions about these Terms, contact us at:{'\n'}
            Email: support@aweyy.com{'\n'}
          </Text>
        </ScrollView>
      </SafeAreaView>
    </LinearGradient>
  );
}

const styles = StyleSheet.create({
  background: {
    flex: 1,
  },
  safeArea: {
    flex: 1,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 15,
    paddingHorizontal: 15,
  },
  backButton: {
    padding: 5,
  },
  headerTitle: {
    fontSize: 20,
    fontWeight: 'bold',
    color: '#333',
    marginLeft: 10,
  },
  contentContainer: {
    padding: 20,
  },
  sectionTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#4A4A4A',
    marginBottom: 10,
  },
  subTitle: {
    fontSize: 16,
    fontWeight: '600',
    color: '#555',
    marginTop: 20,
    marginBottom: 10,
  },
  content: {
    fontSize: 14,
    lineHeight: 22,
    color: '#666',
    textAlign: 'justify',
  },
});
