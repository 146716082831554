import React, { useState } from 'react';
import {
  View,
  Text,
  TextInput,
  TouchableOpacity,
  StyleSheet,
  StatusBar,
  SafeAreaView,
  Alert,
  Modal,
  FlatList,
  ScrollView,
  KeyboardAvoidingView,
  Platform,
} from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import { Ionicons } from '@expo/vector-icons';
import { firestore } from '../../firebaseConfig';
import { collection, doc, getDoc, setDoc, updateDoc, increment } from 'firebase/firestore';
import { useNavigation } from '@react-navigation/native';

const categories = ['Accommodation', 'Beauty', 'Books', 'Food', 'Jewelry', 'Tattoos', 'Thrift Shops'];

const RecommendationsScreen: React.FC = () => {
  const navigation = useNavigation();
  const [placeName, setPlaceName] = useState('');
  const [location, setLocation] = useState('');
  const [category, setCategory] = useState('');
  const [details, setDetails] = useState('');
  const [instagram, setInstagram] = useState('');
  const [isCategoryModalVisible, setCategoryModalVisible] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  const handleRecommendation = async () => {
    if (!placeName || !location || !category || !details) {
      Alert.alert('Error', 'Please fill out all required fields.');
      return;
    }

    const placeNameLower = placeName.toLowerCase();
    const locationLower = location.toLowerCase();
    const categoryLower = category.toLowerCase();

    try {
      const recommendationsRef = doc(
        collection(firestore, 'recommendations'),
        `${placeNameLower}_${locationLower}_${categoryLower}`
      );

      const docSnap = await getDoc(recommendationsRef);

      if (docSnap.exists()) {
        await updateDoc(recommendationsRef, {
          recommendationCount: increment(1),
        });
      } else {
        await setDoc(recommendationsRef, {
          placeName: placeNameLower,
          location: locationLower,
          category: categoryLower,
          details,
          instagram: instagram || null,
          recommendationCount: 1,
          createdAt: new Date().toISOString(),
        });
      }

      Alert.alert('Success', 'Thank you for your recommendation!');
      setPlaceName('');
      setLocation('');
      setCategory('');
      setDetails('');
      setInstagram('');
    } catch (error) {
      Alert.alert('Error', 'An error occurred while submitting your recommendation. Please try again.');
      console.error('Error submitting recommendation:', error);
    }
  };

  return (
    <View style={styles.container}>
      <StatusBar barStyle="dark-content" backgroundColor="transparent" translucent />
      <LinearGradient colors={['#FFDEE9', '#ffffff']} style={styles.background}>
        <SafeAreaView>
          <View style={styles.header}>
            <TouchableOpacity onPress={() => navigation.goBack()} style={styles.backButton}>
              <Ionicons name="arrow-back" size={22} color="#333" />
            </TouchableOpacity>
            <Text style={styles.headerTitle}>Recommend a Place</Text>
          </View>
        </SafeAreaView>

        <KeyboardAvoidingView
          behavior={Platform.OS === 'ios' ? 'padding' : undefined}
          style={styles.keyboardView}
        >
          <ScrollView contentContainerStyle={styles.scrollContainer} keyboardShouldPersistTaps="handled">
            <View style={styles.inputGroup}>
              <Text style={styles.label}>Name of the recommended place</Text>
              <TextInput
                style={styles.input}
                value={placeName}
                onChangeText={setPlaceName}
              />
            </View>

            <View style={styles.inputGroup}>
              <Text style={styles.label}>Location (e.g. Bali, London)</Text>
              <TextInput
                style={styles.input}
                value={location}
                onChangeText={setLocation}
              />
            </View>

            <View style={styles.inputGroup}>
              <Text style={styles.label}>Category</Text>
              <TouchableOpacity style={[styles.input, styles.categoryInput]} onPress={() => setCategoryModalVisible(true)}>
                <Text style={styles.dropdownButtonText}>
                  {category ? category : 'Select a Category'}
                </Text>
                <Ionicons name="chevron-down" size={22} color="#333" style={styles.iconSpacing} />
              </TouchableOpacity>
            </View>

            <View style={styles.inputGroup}>
              <Text style={styles.label}>Details about the place</Text>
              <TextInput
                style={[styles.input, styles.textArea]}
                value={details}
                onChangeText={setDetails}
                multiline
              />
            </View>

            <View style={styles.inputGroup}>
              <Text style={styles.label}>Instagram/Website (optional)</Text>
              <TextInput
                style={styles.input}
                value={instagram}
                onChangeText={setInstagram}
              />
            </View>

            <TouchableOpacity style={styles.submitButton} onPress={handleRecommendation}>
              <Text style={styles.submitButtonText}>Recommend</Text>
            </TouchableOpacity>
          </ScrollView>
        </KeyboardAvoidingView>

        {/* Category Modal */}
        <Modal transparent={true} visible={isCategoryModalVisible} animationType="slide">
          <View style={styles.modalOverlay}>
            <View style={styles.modalContainer}>
              <Text style={styles.modalTitle}>Select Category</Text>
              <FlatList
                data={categories}
                keyExtractor={(item) => item}
                renderItem={({ item }) => (
                  <TouchableOpacity
                    style={styles.categoryCard}
                    onPress={() => {
                      setCategory(item);
                      setCategoryModalVisible(false);
                    }}
                  >
                    <Text style={styles.categoryText}>{item}</Text>
                  </TouchableOpacity>
                )}
              />
              <TouchableOpacity onPress={() => setCategoryModalVisible(false)} style={styles.closeModalButton}>
                <Text style={styles.closeModalText}>Close</Text>
              </TouchableOpacity>
            </View>
          </View>
        </Modal>
      </LinearGradient>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  background: {
    flex: 1,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 15,
    paddingHorizontal: 15,
    marginBottom: 25,
  },
  backButton: {
    padding: 5,
  },
  headerTitle: {
    fontSize: 20,
    fontWeight: 'bold',
    color: '#333',
    marginLeft: 10,
  },
  keyboardView: {
    flex: 1,
  },
  scrollContainer: {
    alignItems: 'center',
    paddingBottom: 20,
  },
  inputGroup: {
    marginBottom: 15,
    width: '90%',
  },
  label: {
    fontSize: 14,
    fontWeight: '600',
    color: '#333',
    marginBottom: 5,
  },
  input: {
    height: 50,
    backgroundColor: '#fff',
    borderColor: '#FFB6C1',
    borderWidth: 2,
    borderRadius: 15,
    paddingHorizontal: 15,
    fontSize: 14,
    color: '#333',
  },
  categoryInput: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  textArea: {
    height: 100,
    textAlignVertical: 'top',
  },
  dropdownButtonText: {
    fontSize: 14,
    color: '#a4a4a4',
    fontWeight: '500',
  },
  iconSpacing: {
    marginLeft: 5,
  },
  submitButton: {
    backgroundColor: '#FFB6C1',
    paddingVertical: 15,
    borderRadius: 15,
    marginTop: 20,
    width: 200,
    alignItems: 'center',
  },
  submitButtonText: {
    fontSize: 18,
    color: '#FFF',
    fontWeight: '600',
  },
  modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
  modalContainer: {
    backgroundColor: '#FFF',
    marginHorizontal: 20,
    borderRadius: 15,
    padding: 20,
    maxHeight: '80%',
    alignItems: 'center',
  },
  modalTitle: {
    fontSize: 24,
    fontWeight: '700',
    marginBottom: 20,
  },
  categoryCard: {
    backgroundColor: '#FFF0F6',
    paddingVertical: 15,
    paddingHorizontal: 20,
    borderRadius: 15,
    marginVertical: 5,
    width: 250,
    alignItems: 'center',
    borderWidth: 2,
    borderColor: '#FFB6C1',
  },
  categoryText: {
    fontSize: 18,
    color: '#333',
    fontWeight: '600',
  },
  closeModalButton: {
    marginTop: 20,
    backgroundColor: '#FFB6C1',
    paddingVertical: 10,
    paddingHorizontal: 30,
    borderRadius: 15,
  },
  closeModalText: {
    fontSize: 16,
    color: '#fff',
    fontWeight: '600',
  },
});

export default RecommendationsScreen;
