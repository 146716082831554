// App.tsx
import 'react-native-gesture-handler';
import React, { useState, useEffect } from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { ActivityIndicator, View, TouchableOpacity, Text, Image, StyleSheet, Platform, ScrollView } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import * as Font from 'expo-font';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { auth, firestore } from './firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';
import { User } from 'firebase/auth';

import WelcomeScreen from './app/screens/WelcomeScreen';
import LocationSelector from './app/screens/LocationSelector';
import CategorySelector from './app/screens/CategorySelector';
import HomePage from './app/screens/HomePage';
import ExplorePage from './app/screens/ExplorePage';
import ProfilePage from './app/screens/ProfilePage';
import VendorProfileScreen from './app/screens/VendorProfileScreen';
import EditVendorProfile from './app/screens/EditVendorProfile';
import AllVendorsScreen from './app/screens/AllVendorsScreen';
import SharedListScreen from './app/screens/SharedListScreen';
import LoginScreen from './app/screens/LoginScreen';
import SignupScreen from './app/screens/SignUpScreen';
import TermsofServiceScreen from './app/screens/TermsofServiceScreen';
import PrivacyPolicyScreen from './app/screens/PrivacyPolicyScreen';
import ContactScreen from './app/screens/ContactScreen';
import RecommendationScreen from './app/screens/RecommendationsScreen';
import RequestAdScreen from './app/screens/RequestAdScreen';
import AccountSettingsScreen from './app/screens/AccountSettingsScreen';
import PrivacySettingsScreen from './app/screens/PrivacySettingsScreen';
import HelpCenterScreen from './app/screens/HelpCenterScreen';
import VendorApplyScreen from './app/screens/VendorApplyScreen';

export type RootStackParamList = {
  WelcomeScreen: undefined;
  LocationSelector: undefined;
  CategorySelector: { location: string };
  HomeTabs: { location: string; categories: string[] };
  LoginScreen: undefined;
  SignUpScreen: undefined;
  Profile: undefined;
  EditVendorProfile: undefined;
  VendorProfile: undefined;
  AllVendorsScreen: { category: string; location: string };
  SharedListScreen: undefined;
  Recommendations: undefined;
  RequestAd: undefined;
};

type TabParamList = {
  Home: { location: string; categories: string[] };
  Explore: undefined;
  Profile: undefined;
};

const Stack = createStackNavigator<RootStackParamList>();
const BottomTab = createBottomTabNavigator<TabParamList>();

function HomeTabs({ route, navigation }: { route: { params: { location: string; categories: string[] } }; navigation: any }) {
  const { location, categories } = route.params || { location: '', categories: [] };
  const [selectedTab, setSelectedTab] = useState<'Home' | 'Explore' | 'Profile'>(Platform.OS === 'web' ? (localStorage.getItem('selectedTab') as 'Home' | 'Explore' | 'Profile') || 'Home' : 'Home');

  useEffect(() => {
    if (Platform.OS === 'web') {
      const titles = {
        Home: 'Home - Aweyy',
        Explore: 'Explore - Aweyy',
        Profile: 'Profile - Aweyy',
      };
      document.title = titles[selectedTab] || 'Aweyy'; 
      localStorage.setItem('selectedTab', selectedTab); // Save selectedTab to localStorage for persistence on refresh
      localStorage.setItem('lastRoute', 'HomeTabs');
    }
  }, [selectedTab]);

  return Platform.OS === 'web' ? (
    <View style={styles.webContainer}>
      <View style={styles.headerContainer}>
        <View style={styles.logoContainer}>
          <TouchableOpacity onPress={() => {
            setSelectedTab('Home');
            navigation.navigate('Home', { location, categories });
          }}>
            <Image source={require('./assets/logo1.png')} style={styles.logo as ImageStyle} />
          </TouchableOpacity>
        </View>
        <View style={styles.tabButtons}>
          <TouchableOpacity
            style={styles.tabButton}
            onPress={() => {
              setSelectedTab('Home');
              navigation.navigate('Home', { location, categories });
            }}
          >
            <Text style={selectedTab === 'Home' ? styles.activeText : styles.inactiveText}>Home</Text>
          </TouchableOpacity>

          <TouchableOpacity
            style={styles.tabButton}
            onPress={() => {
              setSelectedTab('Explore');
              navigation.navigate('Explore');
            }}
          >
            <Text style={selectedTab === 'Explore' ? styles.activeText : styles.inactiveText}>Explore</Text>
          </TouchableOpacity>
        </View>
        <TouchableOpacity
          onPress={() => {
            setSelectedTab('Profile');
            navigation.navigate('Profile');
          }}
          style={styles.profileButton}
        >
          <Ionicons name="person-circle-outline" size={32} color="#FFB6C1" />
        </TouchableOpacity>
      </View>

     
        {selectedTab === 'Home' ? (
          <HomePage navigation={navigation} route={{ key: 'Home', name: 'Home', params: { location, categories } }} />
        ) : selectedTab === 'Explore' ? (
          <ExplorePage navigation={navigation} />
        ) : (
          <ProfilePage navigation={navigation} />
        )}
      
    </View>
  ) : (
    <BottomTab.Navigator
      screenOptions={{
        tabBarActiveTintColor: '#FFB6C1',
        tabBarInactiveTintColor: '#b0b0b0',
        tabBarShowLabel: false,
        tabBarStyle: {
          backgroundColor: '#FFF',
          borderTopWidth: 0,
          elevation: 10,
          height: 80,
          paddingBottom: 28,
        },
        headerShown: false,
      }}
    >
      <BottomTab.Screen
        name="Home"
        component={HomePage}
        initialParams={{ location, categories }}
        options={{
          tabBarIcon: ({ focused }) => <Ionicons name="home" size={28} color={focused ? '#FFB6C1' : '#4A4A4A'} />,
        }}
      />
      <BottomTab.Screen
        name="Explore"
        component={ExplorePage}
        options={{
          tabBarIcon: ({ focused }) => <Ionicons name="planet" size={28} color={focused ? '#FFB6C1' : '#4A4A4A'} />,
        }}
      />
      <BottomTab.Screen
        name="Profile"
        component={ProfilePage}
        options={{
          tabBarIcon: ({ focused }) => <Ionicons name="person-circle-outline" size={28} color={focused ? '#FFB6C1' : '#4A4A4A'} />,
        }}
      />
    </BottomTab.Navigator>
  );
}

export default function App() {
  const [user, setUser] = useState<null | User>(null);
  const [loading, setLoading] = useState(true);
  const [location, setLocation] = useState<string | null>(null);
  const [categories, setCategories] = useState<string[] | null>(null);
  const [fontsLoaded, setFontsLoaded] = useState(false);
  const [initialRoute, setInitialRoute] = useState<'WelcomeScreen' | 'HomeTabs' | 'LocationSelector'>('WelcomeScreen');

  useEffect(() => {
    const loadFonts = async () => {
      try {
        await Font.loadAsync({
          Ionicons: require('@expo/vector-icons/build/vendor/react-native-vector-icons/Fonts/Ionicons.ttf'),
        });
        setFontsLoaded(true);
      } catch (error) {
        console.error('Error loading fonts:', error);
        setFontsLoaded(true);
      }
    };
    loadFonts();
  }, []);

  useEffect(() => {
    const fetchUserDataFromFirebase = async () => {
      const currentUser = auth.currentUser;
      if (currentUser) {
        const userRef = doc(firestore, 'users', currentUser.uid);
        const userSnap = await getDoc(userRef);

        if (userSnap.exists()) {
          const userData = userSnap.data();
          setLocation(userData.location);
          setCategories(userData.categories);

          if (userData.location && userData.categories?.length > 0) {
            setInitialRoute('HomeTabs');
          } else {
            setInitialRoute('LocationSelector');
          }
        } else {
          setInitialRoute('LocationSelector');
        }
      } else {
        setInitialRoute('WelcomeScreen');
      }
      setLoading(false);
    };

    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      setUser(authUser);
      if (authUser) {
        fetchUserDataFromFirebase();
      } else {
        setInitialRoute('WelcomeScreen');
        setLoading(false);
      }
    });

    return unsubscribe;
  }, []);

  if (!fontsLoaded || loading) {
    return (
      <View style={styles.loadingContainer}>
        <ActivityIndicator size='large' color="#FFB6C1" />
      </View>
    );
  }

  return (
    <NavigationContainer>
      <Stack.Navigator initialRouteName={initialRoute} screenOptions={{ headerShown: false }}>
        <Stack.Screen name="WelcomeScreen" component={WelcomeScreen} />
        <Stack.Screen name="HomeTabs" component={HomeTabs} initialParams={{ location, categories }} />
        <Stack.Screen name="LocationSelector" component={LocationSelector} />
        <Stack.Screen name="CategorySelector" component={CategorySelector} />
        <Stack.Screen name="LoginScreen" component={LoginScreen} />
        <Stack.Screen name="SignUpScreen" component={SignupScreen} />
        <Stack.Screen name="VendorProfile" component={VendorProfileScreen} />
        <Stack.Screen name="EditVendorProfile" component={EditVendorProfile} />
        <Stack.Screen name="AllVendorsScreen" component={AllVendorsScreen} />
        <Stack.Screen name="SharedListScreen" component={SharedListScreen} />
        <Stack.Screen name="TermsofService" component={TermsofServiceScreen} />
        <Stack.Screen name="PrivacyPolicy" component={PrivacyPolicyScreen} />
        <Stack.Screen name="Contact" component={ContactScreen} />
        <Stack.Screen name="Recommendations" component={RecommendationScreen} />
        <Stack.Screen name="RequestAd" component={RequestAdScreen} />
        <Stack.Screen name="AccountSettings" component={AccountSettingsScreen} />
        <Stack.Screen name="PrivacySettings" component={PrivacySettingsScreen} />
        <Stack.Screen name="HelpCenter" component={HelpCenterScreen} />
        <Stack.Screen name="VendorApplyScreen" component={VendorApplyScreen} />
      </Stack.Navigator>
    </NavigationContainer>
  );
}

const styles = StyleSheet.create({
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  webContainer: {
    flex: 1,
    justifyContent: 'flex-start',
    paddingHorizontal: 0,
    backgroundColor: '#FFFFFF',
  },
  scrollView: {
    height: '100vh',
  },
  scrollContentContainer: {
    flexGrow: 1,
    justifyContent: 'flex-start',
    paddingBottom: 100,
  },
  headerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingVertical: 10,
    marginBottom: 0,
  },
  logoContainer: {
    flex: 1,
    alignItems: 'flex-start',
  },
  logo: {
    width: 220,
    height: 70,
    resizeMode: 'contain',
    marginLeft: 20,
  },
  tabButtons: {
    flexDirection: 'row',
    justifyContent: 'center',
    flex: 2,
  },
  tabButton: {
    alignItems: 'center',
    paddingVertical: 40,
    paddingHorizontal: 20,
    marginHorizontal: 10,
  },
  activeText: {
    color: '#FFB6C1',
    fontSize: 18,
    fontWeight: '800',
  },
  inactiveText: {
    color: '#FFB6C1',
    fontSize: 18,
    fontWeight: '500',
  },
  profileButton: {
    flex: 1,
    alignItems: 'flex-end',
    marginRight: 20,
  },
});
